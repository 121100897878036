import React, { FC } from "react";
import { Box, Stack, Text, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
const Blog = () => (
  <Stack spacing={4} mt="8">
    <Text fontSize="2xl" as="b">
      What is a block number and block time in Ethereum?
    </Text>
    <Text fontSize="1rem">
      Block number and time in Ethereum are closely related. The Ethereum
      blockchain is a distributed ledger that records all transactions that
      occur on the network. Each transaction is recorded in a block, which is
      identified by its block number. As more blocks are added to the chain, the
      block number increases.
    </Text>
    <Text fontSize="1rem">
      Block time in Ethereum is the time between blocks, which is divided into
      12-second units called 'slots'. In each slot, a single validator is
      selected to propose a block. As long as all validators are online and
      functioning, there will be a block in every slot, resulting in a block
      time of 12 seconds. However, if a validator is offline when it is called
      to propose a block, slots may go empty. This is different from
      proof-of-work based systems, where block times are probabilistic and
      adjusted by the protocol's target mining difficulty.
      <Link
        color="blue.500"
        href="https://etherscan.io/chart/blocktime"
        target="_blank"
      >
        Ethereum's average block time <ExternalLinkIcon mx="2px" />
      </Link>
      of 12 seconds reflects the transition from proof-of-work to
      proof-of-stake.
    </Text>
    <Text fontSize="1rem">
      The block number is directly related to the amount of time that has passed
      since the Ethereum network was launched. For example, the first block in
      the Ethereum blockchain, known as the Genesis block, has a block number of
      0. This means that the Ethereum network was launched 0 blocks ago. As more
      blocks are added to the chain, the block number increases, and the amount
      of time that has passed since the launch of the network also increases.
    </Text>
  </Stack>
);

export default Blog;
