import React, { FC } from "react";
import { Flex, Image, Text } from "@chakra-ui/react";

import { ColorModeSwitcher } from "./ColorModeSwitcher";

const NavBar: FC = () => {
  return (
    <Flex
      as="nav"
      maxW="8xl"
      m="0 auto"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
    >
      <Flex align="center" mr={5}>
        <Image src="/logo192.png" w="64px" />
        <Text ml="1rem" fontSize="xl">
          Chain
        </Text>
        <Text fontSize="xl" fontWeight="bold">
          Time
        </Text>
      </Flex>
      {/* <HStack spacing={8} align="center">
                <Text>Home</Text>
                <Text>Blog</Text>
                <Text>Projects</Text>
                <Text>About</Text>
            </HStack> */}
      <ColorModeSwitcher justifySelf="flex-end" />
    </Flex>
  );
};

export default NavBar;
