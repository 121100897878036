import * as React from "react";
import {
  ChakraProvider,
  Box,
  VStack,
  Container,
  theme,
} from "@chakra-ui/react";
import BlockNumberForm from "./components/BlockNumberForm";
import Blog from "./components/Blog";
import LatestBlockPlane from "./components/CurrentBlockPlane";
import NavBar from "./components/NavBar";
// import { Logo } from "./Logo"

export const App = () => (
  <ChakraProvider theme={theme}>
    <NavBar></NavBar>
    <Box textAlign="center" fontSize="xl">
      <Container maxW="2xl" textAlign="left">
        {/* <VStack spacing={8}> */}
        <BlockNumberForm></BlockNumberForm>
        <Blog></Blog>

        {/* </VStack> */}
      </Container>
    </Box>
  </ChakraProvider>
);
