import _chains from "../data/chains.json";
import React, { Dispatch, SetStateAction } from "react";

export type Chain = {
  chainId: string;
  name: string;
  rpcUrl: string;
  blockAvgTime: number;
  explorerUrl: string;
  icon: string;
};

const chains = _chains as Chain[];

function useChain(): [Chain, Dispatch<SetStateAction<Chain>>, Chain[]] {
  const [chain, setChain] = React.useState<Chain>(chains[0]);

  return [chain, setChain, chains];
}

export default useChain;
