import React, { FC, useEffect } from "react";
import {
  Box,
  Text,
  Input,
  Image,
  Container,
  Button,
  FormControl,
  FormLabel,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Stack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HStack,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { ethers } from "ethers";
import { ChevronDownIcon, ExternalLinkIcon, CopyIcon } from "@chakra-ui/icons";

import useChain from "../hooks/useChain";
import LatestBlockPlane from "../components/CurrentBlockPlane";

type BlockNumberFormProps = {};

const BlockNumberForm: FC<BlockNumberFormProps> = (props) => {
  // Set default timestamp to current time
  const now = new Date();
  const defaultTimestamp = Math.floor(now.getTime() / 1000);
  const [timestamp, setTimestamp] = React.useState(defaultTimestamp);
  const [dateTime, setDateTime] = React.useState(
    now.toISOString().split(".")[0]
  );

  const [block, setBlock] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [chain, setChain, chains] = useChain();
  const { onCopy, value, setValue, hasCopied } = useClipboard("");
  const toast = useToast();

  function onInputchange(event: React.ChangeEvent<HTMLInputElement>) {
    // Convert to date
    const newDate = new Date(parseInt(event.target.value) * 1000);
    setDateTime(newDate.toISOString().split(".")[0]);

    setTimestamp(parseInt(event.target.value));
  }

  function onDTInputchange(event: React.ChangeEvent<HTMLInputElement>) {
    console.log(event.target.value);
    // Get the time zone offset
    const offset = new Date().getTimezoneOffset() * 60;
    // Convert to unix timestamp - time zone offset
    const newDate = new Date(event.target.value);
    newDate.setSeconds(newDate.getSeconds() - offset);

    setTimestamp(newDate.getTime() / 1000);
    setDateTime(event.target.value);
  }

  async function onSubmit() {
    setBlock("");
    setIsSubmitting(true);
    if (!chain) {
      setIsSubmitting(false);
      return;
    }
    setChain(chain);
    const provider = new ethers.JsonRpcProvider(chain.rpcUrl);
    // get current block number
    const currentBlockNumber = await provider.getBlockNumber();
    let block = await provider.getBlock(currentBlockNumber);
    let averageBlockTime = chain.blockAvgTime;
    let blockNumber = currentBlockNumber;
    const targetTimestamp = timestamp;
    let lowerbound = 0;
    let upperbound = currentBlockNumber;
    let requestsMade = 0;

    while (block && requestsMade < 100 && blockNumber > 0) {
      let increaseBlocks =
        (targetTimestamp - block.timestamp) / averageBlockTime;
      if (Math.abs(increaseBlocks) < 1) {
        break;
      }
      increaseBlocks = Math.round(increaseBlocks);
      blockNumber += increaseBlocks;
      if (blockNumber > upperbound) {
        blockNumber = upperbound;
        break;
      } else if (blockNumber < lowerbound) {
        blockNumber = lowerbound;
        break;
      }
      const newBlock = await provider.getBlock(blockNumber);

      // Adjust the average block time
      if (newBlock) {
        averageBlockTime =
          (newBlock.timestamp - block.timestamp) /
          (newBlock.number - block.number);
      }
      block = newBlock;
      requestsMade += 1;

      // console.log("blockNumber", blockNumber, "averageBlockTime", averageBlockTime, "requestsMade", requestsMade);
    }

    setBlock(blockNumber.toString());
    setValue(blockNumber.toString());
    setIsSubmitting(false);
  }

  return (
    <Stack>
      <Text fontSize="4xl" as="b">
        Block number by UNIX timestamp
      </Text>
      <FormControl>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            <HStack>
              <Image
                boxSize="2rem"
                borderRadius="full"
                src={chain.icon}
                mr="12px"
              />
              <span>{chain.name}</span>
            </HStack>
          </MenuButton>
          <MenuList>
            {chains.map((chain) => (
              <MenuItem
                minH="48px"
                key={chain.name}
                onClick={() => {
                  setChain(chain);
                }}
              >
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src={chain.icon}
                  alt={chain.name}
                  mr="12px"
                ></Image>
                <span>{chain.name}</span>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </FormControl>
      <FormControl>
        <FormLabel>Time Stamp</FormLabel>
        <Input
          value={timestamp}
          placeholder="Time Stamp"
          type="number"
          onChange={onInputchange}
        ></Input>
      </FormControl>

      <FormControl>
        <FormLabel>UTC Date Time</FormLabel>
        <Input
          value={dateTime}
          placeholder="Date Time"
          type="datetime-local"
          onChange={onDTInputchange}
        />
      </FormControl>

      <FormControl margin="10px">
        <Button
          colorScheme="teal"
          size="lg"
          onClick={onSubmit}
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </FormControl>
      {block !== "" ? (
        <Box>
          <Stat>
            <StatLabel>Block Number</StatLabel>
            <StatNumber>
              <span>{block}</span>
              <Link
                onClick={() => {
                  onCopy();
                  toast({
                    title: "Copied!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                }}
                px={2}
              >
                <CopyIcon />
              </Link>
            </StatNumber>
            <StatHelpText>
              <Link
                href={`${chain.explorerUrl}/block/${block}`}
                target="_blank"
              >
                <HStack>
                  <Text>View on Block Explorer</Text>
                  <ExternalLinkIcon mx="2px" />
                </HStack>
              </Link>
            </StatHelpText>
          </Stat>
        </Box>
      ) : null}
      <LatestBlockPlane chain={chain}></LatestBlockPlane>
    </Stack>
  );
};

export default BlockNumberForm;
