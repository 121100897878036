import React, { FC, useEffect } from "react";
import {
  Box,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Stack,
  Link,
  HStack,
  useClipboard,
  useToast,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { ethers } from "ethers";
import { ExternalLinkIcon, CopyIcon } from "@chakra-ui/icons";

import { Chain } from "../hooks/useChain";

type LatestBlockPlaneProps = {
  chain: Chain;
};

const LatestBlockPlane: FC<LatestBlockPlaneProps> = (props) => {
  const [block, setBlock] = React.useState("");
  const [avgBlock, setAvgBlock] = React.useState("");
  const { chain } = props;

  useEffect(() => {
    setBlock("");
    setAvgBlock("");
    const provider = new ethers.JsonRpcProvider(chain.rpcUrl);

    const getBlock = async () => {
      const currentBlockNumber = await provider.getBlockNumber();
      setBlock(currentBlockNumber?.toString());
    };

    const getAvgBlock = async () => {
      const currentBlockNumber = await provider.getBlockNumber();
      const currentBlock = await provider.getBlock(currentBlockNumber);
      const prevBlock = await provider.getBlock(currentBlockNumber - 100);
      const avgBlockTime =
        ((currentBlock?.timestamp || 0) - (prevBlock?.timestamp || 1)) / 100;
      setAvgBlock(avgBlockTime.toString());
    };

    provider.on("block", getBlock);
    getBlock();
    getAvgBlock();
    return () => {
      provider.off("block", getBlock);
    };
  }, [chain]);

  const { onCopy } = useClipboard("");
  const toast = useToast();

  return (
    <Stack>
      <Text fontSize="4xl" as="b">
        Latest {chain.name} Block
      </Text>
      <Flex>
        {block !== "" ? (
          <Stat>
            <StatLabel>Block Number</StatLabel>
            <StatNumber>
              <span>{block}</span>
              <Link
                onClick={() => {
                  onCopy();
                  toast({
                    title: "Copied!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                }}
                px={2}
              >
                <CopyIcon />
              </Link>
            </StatNumber>
            <StatHelpText>
              <Link
                href={`${chain.explorerUrl}/block/${block}`}
                target="_blank"
              >
                <HStack>
                  <Text>View on Block Explorer</Text>
                  <ExternalLinkIcon mx="2px" />
                </HStack>
              </Link>
            </StatHelpText>
          </Stat>
        ) : (
          <Spinner></Spinner>
        )}
        {avgBlock !== "" ? (
          <Stat px={2}>
            <StatLabel>Average Block Time</StatLabel>
            <StatNumber>
              <span>{avgBlock}</span>
              <Link
                onClick={() => {
                  onCopy();
                  toast({
                    title: "Copied!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                }}
                px={2}
              >
                <CopyIcon />
              </Link>
            </StatNumber>
            <StatHelpText>
              <Text>By latest 100 block</Text>
            </StatHelpText>
          </Stat>
        ) : (
          <Spinner></Spinner>
        )}
      </Flex>
    </Stack>
  );
};

export default LatestBlockPlane;
